<template>
    <div class="breadcrumb-wrapper">
        <a-breadcrumb :routes="routes">
            <template
                slot="itemRender"
                slot-scope="{ route, routes }"
            >
                <span v-if="routes.indexOf(route) === routes.length - 1">
                    {{ route.breadcrumbName }}
                </span>
                <a v-else href="javascript:;" @click="goIntoPage(route)">{{ route.breadcrumbName }}</a>
            </template>
        </a-breadcrumb>
    </div>
</template>

<script>
export default {
    name: "Breadcrumb", // 面包屑组件
    props: {
        routes: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    methods: {
        goIntoPage(route) {
            if (!route.path) return

            // 跳转类型
            if (route.type) {
                // 返回页面数量
                if (route.back < 0) {
                    this.$router[ route.type ](route.back)
                } else {
                    this.$router[ route.type ]({ path: route.path })
                }

                return
            }
            this.$router.replace({ path: route.path })
        }
    }
};
</script>

<style lang="less" scoped>
.breadcrumb-wrapper {
    margin: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #fff;
    padding: 0 20px;
}
</style>