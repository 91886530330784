<template>
    <div class="ordinary-users-btns">
        <a-button class="btn" type="primary" @click="handleEecovery">恢复正常用户</a-button>
        <a-button class="btn" type="danger" @click="handleBlack">拉黑用户</a-button>
    </div>
</template>

<script>
import { userBlackEecovery } from 'network/usersManage'

export default {
    name: "OrdinaryUsersBtns", // 商家操作按钮
    props: {
        checkKeys: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        // -------------事件处理函数---------------
        // 恢复正常用户
        handleEecovery() {
            let ids = this.checkKeys.join(',');
            this._userBlackEecovery(0, ids)
        },
        // 拉黑用户
        handleBlack() {
            let ids = this.checkKeys.join(',');
            this._userBlackEecovery(1, ids)
        },

        // -------------网络请求---------------
        // 启用/停用
        _userBlackEecovery(blacklist, ids) {
            userBlackEecovery(blacklist, ids).then(res => {
                this.$message.success(res.message);
                
                this.$emit('onSuccess')
            }).catch(err => {})
        },
    },
};
</script>

<style lang="less" scoped>
.ordinary-users-btns {
    width: 100%;
    margin: 20px auto;

    .btn {
        margin-right: 20px;
    }
}
</style>