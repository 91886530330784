<template>
    <div class="users-list"
         ref="listBox">
        <a-table :columns="columns"
                 :data-source="list"
                 :pagination="false"
                 :loading="loading"
                 :scroll="scroll" 
                 :row-key="record => record.id"
                 :row-selection="{ selectedRowKeys: checkKeys, onChange: onSelectChange }"
        />
    </div>
</template>

<script>
export default {
    name: 'OrdinaryUsersList', // 用户列表
    props: {
        list: {
            type: Array,
            default () {
                return []
            }
        },
        checkKeys: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            columns: [
                {
                    title: '用户id',
                    dataIndex: 'id',
                    width: 260,
                },
                {
                    title: '用户手机',
                    dataIndex: 'phone',
                    width: 200,
                },
                {
                    title: '用户昵称',
                    dataIndex: 'name',
                    width: 200,
                },
                {
                    title: '用户状态',
                    dataIndex: 'status',
                    width: 150,
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                },
            ],
            scroll: {
                y: 480
            }
        }
    },
    methods: {
        onSelectChange(selectedRowKeys) {
            this.$emit('update:checkKeys', selectedRowKeys)
        },
    }
}
</script>

<style lang="less" scoped>
.users-list {
    width: 100%;
    // height: 100%;
    overflow: auto;
}
</style>