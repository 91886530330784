import api from './api'
import { request } from './request'

// 获取用户列表
function getUsersList(pageNumber, pageSize, params = {
    phone: '',
    userType: '',
    closed: '',
    realname: '',
    username: '',
    operatorName: ''
}) {
    return request({
        url: api.userManage.list,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

// 创建用户
function createUser({ closed = 0, password, phone, realname, remark, roleId = '', userType, username, certificationPath, operatorName, productionPermissions, settlementMode }) {
    return request({
        url: api.userManage.create,
        method: 'post',
        data: { closed, password, phone, realname, remark, roleId, userType, username, certificationPath, operatorName, productionPermissions, settlementMode }
    })
}

// 编辑用户
function editUser({ closed = 0, id, password, phone, realname, remark, roleId = '', userType, username, certificationPath, operatorName, productionPermissions, settlementMode }) {
    return request({
        url: api.userManage.edit,
        method: 'post',
        data: { closed, id, password, phone, realname, remark, roleId, userType, username, certificationPath, operatorName, productionPermissions, settlementMode }
    })
}

// 获取用户详情
function getUserDetail(id) {
    return request({
        url: api.userManage.detail + `/${ id }`,
        method: 'get'
    })
}

// 启用/停用
function userOpenClose(closed, ids) {
    return request({
        url: api.userManage.openClose + `/${ closed }/${ ids }`,
        method: 'post'
    })
}

// 拉黑/恢复
function userBlackEecovery(blacklist, ids) {
    return request({
        url: api.userManage.blackEecovery + `/${ blacklist }/${ ids }`,
        method: 'post'
    })
}

// 修改密码
function userChangPassword(id, password) {
    return request({
        url: api.userManage.changePwd,
        method: 'post',
        data: { id, password }
    })
}

export {
    getUsersList,
    createUser,
    editUser,
    getUserDetail,
    userOpenClose,
    userBlackEecovery,
    userChangPassword
}