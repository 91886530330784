<template>
    <div id="ordinary-users">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="ordinary-users-data">
            <!-- 筛选 -->
            <ordinary-search @onSearch="onSearch" />

            <!-- 操作按钮 -->
            <ordinary-users-btns :check-keys="checkKeys" @onSuccess="onSuccess" />

            <!-- 列表 -->
            <div class="users-list-wrapper">
                <ordinary-users-list :list="usersList" :check-keys.sync="checkKeys" :loading="loading" />
            </div>

            <!-- 分页 -->
            <pagination :page.sync="page" :page-size="pageSize" :total-size="totalSize" @onPageChange="onPageChange" @onSizeChange="onSizeChange" />
        </div>
    </div>
</template>

<script>
import OrdinarySearch from './childComps/OrdinarySearch.vue'
import OrdinaryUsersBtns from './childComps/OrdinaryUsersBtns.vue'
import OrdinaryUsersList from './childComps/OrdinaryUsersList.vue'

import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import Pagination from 'components/pagination/Pagination'

import { getUsersList } from 'network/usersManage'

export default {
    name: 'OrdinaryUsers', // 普通用户管理
    components: {
        OrdinarySearch,
        OrdinaryUsersBtns,
        OrdinaryUsersList,
        Breadcrumb,
        Pagination
    },
    data () {
        return {
            breadcrumb: [
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: "/ordinaryUsers",
                    breadcrumbName: "普通用户管理",
                },
            ],

            phone: '',
            status: '',

            page: 1,
            pageSize: 10,
            totalSize: 0,
            loading: false,

            usersList: [],
            checkKeys: []
        }
    },
    created () {
        this._getUsersList(this.page, this.pageSize, {
            phone: '',
            userType: '2'
        })
    },
    methods: {
        // ------------------事件处理函数-----------------
        // 查询
        onSearch (values) {
            this.phone = values.phone;
            this.status = values.status;

            let params = {
                phone: values.phone,
                closed: values.status,
                userType: '2'
            }

            this.page = 1;

            this._getUsersList(this.page, this.pageSize, params)
        },

        // 切换页码
        onPageChange(page) {
            this.page = page;

            let params = {
                phone: this.phone,
                closed: this.status,
                userType: '2'
            }

            this._getUsersList(this.page, this.pageSize, params)
        },

        // 切换一页多少条
        onSizeChange (pageSize) {
            this.pageSize = pageSize;

            let params = {
                phone: this.phone,
                closed: this.status,
                userType: '2'
            }

            this._getUsersList(this.page, this.pageSize, params)
        },

        onSuccess() {
            let params = {
                phone: this.phone,
                closed: this.status,
                userType: '2'
            }

            this._getUsersList(this.page, this.pageSize, params)
        },

        // -------------------网络请求---------------
        // 获取用户列表
        _getUsersList (pageNumber, pageSize, params = {
            phone: '',
            closed: '',
            userType: '2'
        }) {
            this.loading = true;

            getUsersList(pageNumber, pageSize, params).then(res => {
                this.totalSize = res.totalSize;
                this.page = res.currentSize > 0 ? pageNumber : this.page;

                let list = res.data;

                let newList = [];

                class User {
                    constructor(obj) {
                        for (const key in obj) {
                            this[ key ] = obj[ key ];
                        }
                    }
                }

                for (let i = 0; i < list.length; i++) {
                    let item = list[ i ];
                    let user = new User({
                        key: item.id,
                        name: item.realname, 
                        status: item.closed == 1 ? '拉黑' : '正常',
                        ...item
                    })

                    for (const key in user) {
                        !user[ key ] && user[ key ] != 0 && (user[ key ] = '暂无数据')
                    }

                    newList.push(user)
                }

                this.loading = false;
                this.usersList = newList;
            }).catch(err => {
                this.loading = false;
            })
        }
    }
};
</script>

<style lang="less" scoped>
#ordinary-users {
    width: 100%;
    // height: 100%;
    position: relative;
    overflow: auto;
    // display: flex;
    // flex-direction: column;

    .ordinary-users-data {
        // flex: 1;
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        // display: flex;
        // flex-direction: column;
        overflow: auto;

        .users-list-wrapper {
            // flex: 1;
            width: 100%;
            margin: 20px auto;
            overflow: auto;
        }
    }
}
</style>